export const LOCATIONS = [
  { code: "ca1", country: "Canada", url: "ca1.aconex.com" },
  {
    code: "uk1",
    country: "Canada/Africa/Europe",
    url: "uk1.aconex.co.uk",
  },
  { code: "us1", country: "US & Latin America", url: "us1.aconex.com" },
  { code: "us2", country: "US Gov", url: "us2.aconex.com" },
  { code: "eu1", country: "Europe/ALDAR", url: "eu1.aconex.com" },
  { code: "ksa1", country: "Saudi Arabia", url: "ksa1.aconex.com" },
  { code: "mea", country: "Rest of Middle East ", url: "mea.aconex.com" },
  { code: "cn1", country: "CN1", url: "cn1.aconexasia.com" },
  { code: "hk1", country: "Rest of Asia", url: "hk1.aconex.com" },
  { code: "au1", country: "Australia/NZ", url: "au1.aconex.com" },
  { code: "au2", country: "AU Gov", url: "au2.aconex.com" },
]
