
import ConnectorDetails from "@/components/connectors/ConnectorDetails"
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent, ProjectFeatureFlag } from "@evercam/shared/types"

export default {
  components: {
    ConnectorDetails,
  },
  data() {
    return {
      isLoading: false,
      voyageControlDialog: false,
      selectedLocationUrl: "",
      locationUrls: [],
      voyageControlCode: "",
      voyageControlEmail: "",
      selectedProjectExid: "",
      voyageControlVenue: "",
      voyageControlApiKey: "",
    }
  },
  computed: {
    ...mapStores(useConnectorStore, useAccountStore, useProjectStore),
    gateReportEnableProjects() {
      return this.projectStore.projects?.filter((project) =>
        project.featureFlags?.includes(ProjectFeatureFlag.GATE_REPORT)
      )
    },
  },
  watch: {
    voyageControlDialog(visible) {
      this.$analytics.saveEvent(
        AnalyticsEvent.connectorsToggleVoyageControlDialog,
        { visible }
      )
    },
  },
  methods: {
    updateSelectedProject(projectExid) {
      this.selectedProjectExid = projectExid
    },
    async connectVoyageControl() {
      try {
        this.$analytics.saveEvent(
          AnalyticsEvent.connectorsConnectToVoyageControl
        )
        this.isLoading = true
        await EvercamApi.voyageControl.connect({
          projectExid: this.selectedProjectExid,
          email: this.voyageControlEmail,
          venue: this.voyageControlVenue,
          apiKey: this.voyageControlApiKey,
        })
        await this.connectorStore.getConnectors()
        this.$notifications.success(
          this.$t("content.connectors.voyage_control_success")
        )
        this.voyageControlDialog = false
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.voyage_control_error"),
          error,
        })
        this.voyageControlDialog = true
      } finally {
        this.selectedProjectExid = ""
        this.isLoading = false
      }
    },
    openVoyageControlDialog() {
      this.voyageControlDialog = true
      this.voyageControlEmail = this.accountStore.email
    },
  },
}
