
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { LOCATIONS } from "@evercam/shared/constants/aconex"
import ConnectorDetails from "@/components/connectors/ConnectorDetails"
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: {
    ConnectorDetails,
  },
  data() {
    return {
      aconexDialog: false,
      selectedLocationUrl: "",
      locationUrls: [],
      aconexCode: "",
      aconexEmail: "",
    }
  },
  computed: {
    ...mapStores(useConnectorStore, useAccountStore),
    connexionLink() {
      if (!this.selectedLocationUrl) {
        return ""
      }
      const redirectUri = `${window.location.origin}/v2/users/connectors`

      return `https://${this.selectedLocationUrl}/Logon?Action=StartOAuth&client_id=${this.$config.public.aconexClientId}&redirect_uri=${redirectUri}&email=${this.aconexEmail}`
    },
  },
  watch: {
    selectedLocationUrl(url) {
      if (url) {
        const aconexLocationCode = LOCATIONS.find(
          (item) => item.url === url
        )?.code
        window.localStorage?.setItem("aconexLocationCode", aconexLocationCode)
      }
    },
    aconexDialog(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.connectorsToggleAconexDialog, {
        visible,
      })
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.setLocationUrls()

      if (this.$route.query?.error) {
        const errors = {
          NO_UNIQUE_USER_FOUND:
            "Provided email doesn’t exist or there are multiple users found for specified email",
          SO_IS_DISABLED: "Authentication is disabled on the selected hostname",
          "Error 400 Invalid redirect_uri": "Error 400 Invalid redirect_uri",
          "Error 400 Unknown or invalid client_id":
            "Error 400 Unknown or invalid client_id",
        }
        const errorMessage =
          errors[this.$route.query?.error] || "Failed to login to acconex"
        this.$notifications.error({
          text: errorMessage,
          error: new Error(errorMessage),
        })

        return
      }

      if (this.$route.query?.code) {
        this.aconexCode = this.$route.query?.code
        this.connectWithAconex()
        history.replaceState({}, null, document.location.href.split(/[?#]/g)[0])
      }
    },
    setLocationUrls() {
      this.locationUrls = LOCATIONS.reduce(
        (acc, item) => [
          ...acc,
          {
            text: `${item.country} (${item.url.toUpperCase()})`,
            value: item.url,
          },
        ],
        []
      )
    },
    async connectWithAconex() {
      try {
        await EvercamApi.aconex.authenticate({
          code: this.aconexCode,
          location: window.localStorage.getItem("aconexLocationCode"),
          redirectUri: `${window.location.origin}/v2/users/connectors`,
        })
        await this.connectorStore.getConnectors()
        this.$notifications.success(
          this.$t("content.connectors.aconex_add_success")
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.aconex_add_error"),
          error,
        })
        this.aconexDialog = true
      } finally {
        window.localStorage.removeItem("aconexLocationCode")
      }
    },
    openAconexDialog() {
      this.aconexDialog = true
      this.aconexEmail = this.accountStore.email
    },
    onConnectLinkClick() {
      this.$analytics.saveEvent(AnalyticsEvent.connectorsConnectToAconex)
    },
  },
}
